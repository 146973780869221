<template>
  <div class="demo-page" ref="demoPage">
    <!-- Sticky Header -->
    <StickyHeaderComponent />

    <div v-if="submitted" class="thank-you-message">
      <i class="fas fa-check-circle thank-you-icon"></i> <!-- Checkmark Icon -->
      <h2>Thank you! We got your submission.</h2>
      <button @click="goHome" class="home-button">Take me to Home</button>
    </div>

    <div v-else>


    <!-- Campaign Questionnaire -->
    <div class="questionnaire-container">
      <h1 class="title">Campaign Questionnaire</h1>

        <!-- Section 0: Campaign Type -->
        <div class="question-section">
          <h2 :class="{ 'error': hasErrors.sellType }">
            1. What do you provide? <span v-if="hasErrors.sellType">*</span>
          </h2>
          <div class="other-input">
            <input
              type="text"
              v-model="sellType"
              maxlength="200"
              placeholder="Enter what you sell (200 characters max)"
            />
          </div>
        </div>

                <!-- Section 1: Campaign Type -->
          <div class="question-section">
          <h2 :class="{ 'error': hasErrors.campaignType }">
            2. What type of campaign is this? <span v-if="hasErrors.campaignType">*</span>
          </h2>
          <div class="question-buttons">
            <button 
            v-for="type in campaignTypes" 
            :key="type" 
            @click="toggleCampaignType(type)"
            :class="{'selected': isCampaignTypeSelected(type)}">
            {{ type }}
          </button>
          </div>
        </div>

              <!-- Section 2: Objectives -->
        <div class="question-section">
        <h2 :class="{ 'error': hasErrors.objectives }">
          3. What are the main objective(s) of your marketing campaign? <span v-if="hasErrors.objectives">*</span>
          </h2>
          <div class="question-buttons">
          <button v-for="objective in objectives" :key="objective" @click="toggleObjective(objective)" 
                  :class="{'selected': isObjectiveSelected(objective)}">
            {{ objective }}
          </button>
        </div>
      </div>


        <!-- Section 3: Target Audience Selection -->
        <div class="question-section">
          <h2 :class="{ error: hasErrors.targetAudience }">
            4. Who is your target audience for this campaign?
            <span v-if="hasErrors.targetAudience">*</span>
          </h2>
          <div class="instruction" style="font-size: 10px; font-style: italic;">
            <h3>Targeting Options</h3>
            <p>
              <strong>"All"</strong> indicates that all items are included in the target group and will be analyzed with a normal distribution.
            </p>
            <p>
              <strong>"N/A"</strong> signifies that the option is not relevant to the product and will be excluded entirely from the analysis when generating personas.
            </p>
            <p>
              These selections help ensure that targeting is both accurate and aligned with the intended audience.
            </p>
          </div>

          <div class="dropdown-group">
            <!-- Dropdown for Age -->
            <div>
              <label for="age">Age</label>
              <Multiselect
                v-model="targetAudience.age"
                mode="tags"
                :options="ageOptionsWithDisabled"
                :close-on-select="false"
                :searchable="false"
                :placeholder="'Please select an age(s)'"
                track-by="value"
                label="label"
                @update:model-value="handleAgeSelection"
              />
            </div>

            <!-- Dropdown for Gender -->
            <div>
              <label for="gender">Gender</label>
              <select v-model="targetAudience.gender" id="gender">
                <option disabled value="">Please select a gender group</option>
                <option>Male-leaning</option>
                <option>Female-leaning</option>
                <option>Both</option>
              </select>
            </div>

            <!-- Dropdown for Marital Status -->
            <div>
              <label for="maritalStatus">Marital Status</label>
              <Multiselect
                v-model="targetAudience.maritalStatus"
                mode="tags"
                :options="maritalStatusOptionsWithDisabled"
                :close-on-select="false"
                :searchable="false"
                :placeholder="'Please select a marital status(es)'"
                track-by="value"
                label="label"
                @update:model-value="handleMaritalStatusSelection"
              />
            </div>

            <!-- Dropdown for Family Lifecycle -->
            <div>
              <label for="familyLifecycle">Family Lifecycle</label>
              <Multiselect
                v-model="targetAudience.familyLifecycle"
                mode="tags"
                :options="familyLifecycleOptionsWithDisabled"
                :close-on-select="false"
                :searchable="false"
                :placeholder="'Please select a family lifecycle(s)'"
                track-by="value"
                label="label"
                @update:model-value="handleFamilyLifecycleSelection"
              />
            </div>

            <!-- Dropdown for Income -->
            <div>
              <label for="income">Income</label>
              <Multiselect
                v-model="targetAudience.income"
                mode="tags"
                :options="incomeOptionsWithDisabled"
                :close-on-select="false"
                :searchable="false"
                :placeholder="'Please select an income(s)'"
                track-by="value"
                label="label"
                @update:model-value="handleIncomeSelection"
              />
            </div>

            <!-- Dropdown for Education -->
            <div>
              <label for="education">Education</label>
              <Multiselect
                v-model="targetAudience.education"
                mode="tags"
                :options="educationOptionsWithDisabled"
                :close-on-select="false"
                :searchable="false"
                :placeholder="'Please select an education(s)'"
                track-by="value"
                label="label"
                @update:model-value="handleEducationSelection"
              />
            </div>

            <!-- Dropdown for Region -->
            <div>
              <label for="region">Region</label>
              <Multiselect
                v-model="targetAudience.region"
                mode="tags"
                :options="regionOptionsWithDisabled"
                :close-on-select="false"
                :searchable="false"
                :placeholder="'Please select region(s)'"
                track-by="value"
                label="label"
                @update:model-value="handleRegionSelection"
              />
            </div>

            <!-- Dropdown for City Size -->
            <div>
              <label for="citySize">City Size</label>
              <Multiselect
                v-model="targetAudience.citySize"
                mode="tags"
                :options="citySizeOptionsWithDisabled"
                :close-on-select="false"
                :searchable="false"
                :placeholder="'Please select city size(s)'"
                track-by="value"
                label="label"
                @update:model-value="handleCitySizeSelection"
              />
            </div>

            <!-- Dropdown for Urbanicity -->
            <div>
              <label for="urbancity">Urbanicity</label>
              <Multiselect
                v-model="targetAudience.urbancity"
                mode="tags"
                :options="urbancityOptions"
                :close-on-select="false"
                :searchable="false"
                :placeholder="'Please select urbanicity option(s)'"
                track-by="value"
                label="label"
              />
            </div>
          </div>
        </div>
            <!-- Section 5: Key Message or Value Proposition -->
            <div class="question-section">
              <h2 :class="{ 'error': hasErrors.keyValue }">
                5. What key message or value proposition do you plan to communicate? <span v-if="hasErrors.keyValue">*</span>
              </h2>
              <div class="question-buttons">
                <button 
                  v-for="keyvalue in keyValues" 
                  :key="keyvalue" 
                  @click="toggleKeyValue(keyvalue)"
                  :class="{'selected': isKeyValueSelected(keyvalue)}">
                  {{ keyvalue }}
                </button>
                <div v-if="isKeyValueSelected('Other')" class="other-input">
                  <input type="text" v-model="customKeyValue" maxlength="200" placeholder="Enter your key value (200 characters max)" />
                </div>
              </div>
            </div>


      

          <!-- Section 6: Marketing Channels -->
          <div class="question-section">
            <h2 :class="{ error: hasErrors.marketingChannels }">
              6. Which marketing channels do you intend to use?
              <span v-if="hasErrors.marketingChannels">*</span>
            </h2>
            <div class="question-buttons">
              <button 
                v-for="channel in marketingChannels" 
                :key="channel" 
                @click="toggleMarketingChannel(channel)"
                :class="{ selected: isMarketingChannelSelected(channel) }">
                {{ channel }}
              </button>
              <div v-if="isMarketingChannelSelected('Other')" class="other-input">
                <input
                  type="text"
                  v-model="customMarketingChannel"
                  maxlength="200"
                  placeholder="Please specify other marketing channel"
                />
              </div>
            </div>
          </div>

      <!-- Section 7: How does this campaign align with your overall brand strategy? -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.brandStrategy }">
          7. How does this campaign align with your overall brand strategy? <span v-if="hasErrors.brandStrategy">*</span>
        </h2>
        <div class="question-buttons">
          <button 
            v-for="strategy in brandStrategies" 
            :key="strategy" 
            @click="toggleBrandStrategy(strategy)"
            :class="{'selected': isBrandStrategySelected(strategy)}">
            {{ strategy }}
          </button>
          <div v-if="isBrandStrategySelected('Other')" class="other-input">
            <input type="text" v-model="customBrandStrategy" maxlength="200" placeholder="Enter your brand strategy (200 characters max)" />
          </div>
        </div>
      </div>
      <!-- Section 8: What innovative or creative elements are you planning to incorporate? -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.creativeElements }">
          8. What innovative or creative elements are you planning to incorporate? <span v-if="hasErrors.creativeElements">*</span>
        </h2>
        <div class="question-buttons">
          <button v-for="element in creativeElements" :key="element" @click="toggleElement(element)"
                  :class="{'selected': isElementSelected(element)}">
            {{ element }}
          </button>
          <div v-if="isElementSelected('Other')" class="other-input">
            <input type="text" v-model="customElement" maxlength="200" placeholder="Enter custom element (200 characters max)" />
          </div>
        </div>
      </div>

        <div class="question-section">
          <h2 :class="{ 'error': hasErrors.previousCampaignDiff }">
            9. How does this campaign differ from your previous marketing efforts? <span v-if="hasErrors.previousCampaignDiff">*</span>
          </h2>
          <div class="question-buttons">
            <button 
              v-for="difference in previousCampaignDiff" 
              :key="difference" 
              @click="togglePreviousCampaignDiff(difference)"
              :class="{'selected': isPreviousCampaignDiffSelected(difference)}">
              {{ difference }}
            </button>
            <div v-if="isPreviousCampaignDiffSelected('Other')" class="other-input">
              <input type="text" v-model="customPreviousCampaignDiff" maxlength="200" placeholder="Enter your campaign difference (200 characters max)" />
            </div>
          </div>
        </div>

      <!-- Section 13: Are there any market trends or consumer behaviors influencing your campaign strategy? -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.marketTrend }">
          10. Are there any market trends or consumer behaviors influencing your campaign strategy? <span v-if="hasErrors.marketTrend">*</span>
        </h2>
        <div class="question-buttons">
          <button 
            v-for="trend in marketTrends" 
            :key="trend" 
            @click="toggleMarketTrend(trend)"
            :class="{'selected': isMarketTrendSelected(trend)}">
            {{ trend }}
          </button>
          <div v-if="isMarketTrendSelected('Other')" class="other-input">
            <input type="text" v-model="customMarketTrend" maxlength="200" placeholder="Enter your market trend (200 characters max)" />
          </div>
        </div>
      </div>

      <!-- Section 14: What primary tone or language style will your campaign use? -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.toneStyle }">
          11. What primary tone or language style will your campaign use? <span v-if="hasErrors.toneStyle">*</span>
        </h2>
        <div class="question-buttons">
          <button 
            v-for="tone in toneStyles" 
            :key="tone" 
            @click="toggleToneStyle(tone)"
            :class="{'selected': isToneStyleSelected(tone)}">
            {{ tone }}
          </button>
          <div v-if="isToneStyleSelected('Other')" class="other-input">
            <input type="text" v-model="customToneStyle" maxlength="200" placeholder="Enter your tone style (200 characters max)" />
          </div>
        </div>
      </div>

      <!-- Section 15: Why did you choose this particular tone for your campaign? -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.toneReason }">
          12. Why did you choose this particular tone for your campaign? <span v-if="hasErrors.toneReason">*</span>
        </h2>
        <div class="question-buttons">
          <button 
            v-for="reason in toneReasons" 
            :key="reason" 
            @click="toggleToneReason(reason)"
            :class="{'selected': isToneReasonSelected(reason)}">
            {{ reason }}
          </button>
          <div v-if="isToneReasonSelected('Other')" class="other-input">
            <input type="text" v-model="customToneReason" maxlength="200" placeholder="Enter your reason (200 characters max)" />
          </div>
        </div>
      </div>

      <!-- Section 16: Email Address -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.email }">
          13. Please enter your email address: <span v-if="hasErrors.email">*</span>
        </h2>
        <div class="other-input">
          <input type="email" v-model="email" maxlength="200" placeholder="Enter your email address" />
        </div>
      </div>
      
      <!-- Submit Button -->
      <div class="submit-container">
        <button class="submit-button" @click="submitQuestionnaire">Submit</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import StickyHeaderComponent from '../components/Header-Sticky.vue';
import Multiselect from '@vueform/multiselect';

export default {
  components: {
    StickyHeaderComponent,
    Multiselect
  },
  data() {
    return {
      submitted: false, 

      // Sell Types

      // Campaign Types
      campaignTypes: [
        'Brand awareness campaigns',
        'Product launch campaigns',
        'Lead generation campaigns',
        'Content marketing campaigns',
        'Social media campaigns', 
        'Retargeting campaigns',
        'Seasonal or holiday campaigns',
        'Referral campaigns',
        'User-generated content campaigns',
        'Guerrilla marketing campaigns',
        'Event marketing campaigns',
        'Email marketing campaigns',
        'Cause marketing campaigns',
        'Loyalty program campaigns',
        'Influencer marketing campaigns',
      ],
      selectedCampaignTypes: [],

      // Objectives
      objectives: [
        'Increase brand awareness',
        'Generate leads',
        'Drive sales',
        'Improve customer retention',
        'Launch a new product/service',
        'Enhance brand reputation',
        'Enter a new market',
        'Reposition the brand'
      ],
      selectedObjectives: [],

      // Target Audience Object
      targetAudience: {
        age: [],
        gender: '', // Gender remains a single selection
        maritalStatus: [],
        familyLifecycle: [],
        income: [],
        education: [],
        region: [], // Region remains a single selection
        citySize: [], // City Size remains a single selection
        urbancity: [], // Urbanicity remains a single selection
      },
      ageOptions: ["18-24", "25-35", "36-45", "46-55", "56-65", "65+", "All"],
      maritalStatusOptions: ["Single", "Married", "Divorced", "Widowed", "All", "N/A"],
      familyLifecycleOptions: ["No children", "Youngest child under 6", "Youngest child 6 or over", "Youngest child over 12", "No children under 18", "All", "N/A"],
      incomeOptions: [
        "Below $10,000",
        "$10,000-$29,999",
        "$30,000-$59,999",
        "$60,000-$99,999",
        "$100,000-$149,999",
        "$150,000-$299,999",
        "$300,000+",
        "All"
      ],
      educationOptions: [
        "Grade school or less",
        "Some high school",
        "High School Graduate",
        "Some college",
        "Bachelor's Degree",
        "Advanced Degrees",
        "All",
        "N/A"
      ],
      regionOptions: ["Northeast", "Midwest", "South", "West", "All", "N/A"],
      citySizeOptions: [
        "Under 20k",
        "20k-50k",
        "50k-100k",
        "100k-250k",
        "250k-500k",
        "500k-1m",
        "1m-4m",
        "Over 4m",
        "All",
        "N/A",
      ],
      urbancityOptions: [
        "Urban",
        "Suburban",
        "Rural",
        "Metro Mix",
        "Town",
      ],
        // Key Value
        keyValues: [
        'Product Quality',
        'Cost savings',
        'Convenience',
        'Innovation',
        'Exclusivity',
        'Sustainability',
        'Customer service',
        'Lifestyle enhancement',
        'Problem-solving',
        'Other'
      ],
      selectedKeyValues: [],
      customKeyValue: '',

      // Marketing Channels
      marketingChannels: [
        'Social media',
        'Email marketing',
        'Content marketing',
        'Paid advertising',
        'Influencer marketing',
        'Traditional media (TV, radio, print)',
        'Event marketing',
        'Search engine optimization (SEO)',
        'Direct mail',
        'Other',
      ],
      selectedMarketingChannels: [],
      customMarketingChannel: '',



      // Brand Strategies
      brandStrategies: [
        'Reinforces existing brand image',
        'Introduces a new brand direction',
        'Expands to new market segments',
        'Strengthens customer relationships',
        'Differentiates from competitors',
        'Highlights corporate social responsibility',
        'Showcases innovation',
        'Other',
      ],
      selectedBrandStrategies: [],
      customBrandStrategy: '',

      // Creative Elements
      creativeElements: [
        'Interactive experiences',
        'User-generated content',
        'Augmented or Virtual Reality',
        'Artificial Intelligence',
        'Personalization at scale',
        'Storytelling',
        'Gamification',
        'Live streaming',
        'Other',
      ],
      selectedElements: [],
      customElement: '',


      // Previous Campaign Difference
      previousCampaignDiff: [
        'Targets a new audience',
        'Uses new channels',
        'Incorporates new technologies',
        'Has a larger budget',
        'Focuses on different messaging',
        'Involves more cross-team collaboration',
        'Emphasizes different metrics',
        'Other',
      ],
      selectedPreviousCampaignDiffs: [],
      customPreviousCampaignDiff: '',

      // Market Trends
      marketTrends: [
        'Increased focus on sustainability',
        'Growing importance of social responsibility',
        'Shift towards mobile-first experiences',
        'Rise of voice search and smart speakers',
        'Demand for more personalized experiences',
        'Preference for authentic user-generated content',
        'Increased privacy concerns',
        'Other',
      ],
      selectedMarketTrends: [],
      customMarketTrend: '',

      // Tone Styles
      toneStyles: [
        'Humorous/Playful',
        'Emotional/Sentimental',
        'Formal/Professional',
        'Casual/Conversational',
        'Inspirational/Motivational',
        'Educational/Informative',
        'Provocative/Controversial',
        'Urgent/Time-sensitive',
        'Luxurious/Sophisticated',
        'Minimalist/Straightforward',
        'Empathetic/Understanding',
        'Authoritative/Expert',
        'Nostalgic/Retro',
        'Futuristic/Innovative',
        'Other',
      ],
      selectedToneStyles: [],
      customToneStyle: '',

      // Tone Reasons
      toneReasons: [
        'Aligns with brand personality',
        'Resonates with target audience',
        'Differentiates from competitors',
        'Suits the product/service being marketed',
        'Fits the chosen marketing channels',
        'Addresses current market trends',
        'Reflects cultural context',
        'Supports campaign objectives',
        'Based on successful past campaigns',
        'Other',
      ],
      selectedToneReasons: [],
      customToneReason: '',

      hasErrors: {
        sellType: false,
        campaignType: false,
        objectives: false,
        targetAudience: false,
        marketingChannels: false,
        brandStrategy: false,
        creativeElements: false,
        previousCampaignDiff: false,
        marketTrend: false,
        toneStyle: false,
        toneReason: false,
        email: false,
      },
    };
  },
  computed: {
    ageOptionsWithDisabled() {
      if (this.targetAudience.age.includes('All')) {
        return this.ageOptions.map((option) => ({
          label: option,
          value: option,
          disabled: option !== 'All',
        }));
      } else if (this.targetAudience.age.length > 0) {
        return this.ageOptions.map((option) => ({
          label: option,
          value: option,
          disabled: option === 'All',
        }));
      } else {
        return this.ageOptions.map((option) => ({
          label: option,
          value: option,
          disabled: false,
        }));
      }
    },
    incomeOptionsWithDisabled() {
      if (this.targetAudience.income.includes('All')) {
        return this.incomeOptions.map((option) => ({
          label: option,
          value: option,
          disabled: option !== 'All',
        }));
      } else if (this.targetAudience.income.length > 0) {
        return this.incomeOptions.map((option) => ({
          label: option,
          value: option,
          disabled: option === 'All',
        }));
      } else {
        return this.incomeOptions.map((option) => ({
          label: option,
          value: option,
          disabled: false,
        }));
      }
    },

    // Rule Set 2 Fields
    maritalStatusOptionsWithDisabled() {
      const selected = this.targetAudience.maritalStatus;
      if (selected.includes('All')) {
        return this.maritalStatusOptions.map((option) => ({
          label: option,
          value: option,
          disabled: option !== 'All',
        }));
      } else if (selected.includes('N/A')) {
        return this.maritalStatusOptions.map((option) => ({
          label: option,
          value: option,
          disabled: option !== 'N/A',
        }));
      } else if (selected.length > 0) {
        return this.maritalStatusOptions.map((option) => ({
          label: option,
          value: option,
          disabled: option === 'All' || option === 'N/A',
        }));
      } else {
        return this.maritalStatusOptions.map((option) => ({
          label: option,
          value: option,
          disabled: false,
        }));
      }
    },
    familyLifecycleOptionsWithDisabled() {
      const selected = this.targetAudience.familyLifecycle;
      if (selected.includes('All')) {
        return this.familyLifecycleOptions.map((option) => ({
          label: option,
          value: option,
          disabled: option !== 'All',
        }));
      } else if (selected.includes('N/A')) {
        return this.familyLifecycleOptions.map((option) => ({
          label: option,
          value: option,
          disabled: option !== 'N/A',
        }));
      } else if (selected.length > 0) {
        return this.familyLifecycleOptions.map((option) => ({
          label: option,
          value: option,
          disabled: option === 'All' || option === 'N/A',
        }));
      } else {
        return this.familyLifecycleOptions.map((option) => ({
          label: option,
          value: option,
          disabled: false,
        }));
      }
    },
    educationOptionsWithDisabled() {
      const selected = this.targetAudience.education;
      if (selected.includes('All')) {
        return this.educationOptions.map((option) => ({
          label: option,
          value: option,
          disabled: option !== 'All',
        }));
      } else if (selected.includes('N/A')) {
        return this.educationOptions.map((option) => ({
          label: option,
          value: option,
          disabled: option !== 'N/A',
        }));
      } else if (selected.length > 0) {
        return this.educationOptions.map((option) => ({
          label: option,
          value: option,
          disabled: option === 'All' || option === 'N/A',
        }));
      } else {
        return this.educationOptions.map((option) => ({
          label: option,
          value: option,
          disabled: false,
        }));
      }
    },
    regionOptionsWithDisabled() {
      const selected = this.targetAudience.region;
      if (selected.includes('All')) {
        return this.regionOptions.map((option) => ({
          label: option,
          value: option,
          disabled: option !== 'All',
        }));
      } else if (selected.includes('N/A')) {
        return this.regionOptions.map((option) => ({
          label: option,
          value: option,
          disabled: option !== 'N/A',
        }));
      } else if (selected.length > 0) {
        return this.regionOptions.map((option) => ({
          label: option,
          value: option,
          disabled: option === 'All' || option === 'N/A',
        }));
      } else {
        return this.regionOptions.map((option) => ({
          label: option,
          value: option,
          disabled: false,
        }));
      }
    },
    citySizeOptionsWithDisabled() {
      const selected = this.targetAudience.citySize;
      if (selected.includes('All')) {
        return this.citySizeOptions.map((option) => ({
          label: option,
          value: option,
          disabled: option !== 'All',
        }));
      } else if (selected.includes('N/A')) {
        return this.citySizeOptions.map((option) => ({
          label: option,
          value: option,
          disabled: option !== 'N/A',
        }));
      } else if (selected.length > 0) {
        return this.citySizeOptions.map((option) => ({
          label: option,
          value: option,
          disabled: option === 'All' || option === 'N/A',
        }));
      } else {
        return this.citySizeOptions.map((option) => ({
          label: option,
          value: option,
          disabled: false,
        }));
      }
    },
  },
  methods: {
    goHome() {
      this.$router.push('/'); // Redirect to home page
    },
    // Section 2: Objectives
    toggleObjective(objective) {
      const index = this.selectedObjectives.indexOf(objective);
      if (index > -1) {
        this.selectedObjectives.splice(index, 1);
      } else {
        this.selectedObjectives.push(objective);
      }
    },
    isObjectiveSelected(objective) {
      return this.selectedObjectives.includes(objective);
    },
        // Handle Selection Logic for Rule Set 1 Fields
        handleAgeSelection(selectedOptions) {
      if (selectedOptions.includes('All')) {
        this.targetAudience.age = ['All'];
      } else {
        this.targetAudience.age = selectedOptions.filter(
          (option) => option !== 'All'
        );
      }
    },
    handleIncomeSelection(selectedOptions) {
      if (selectedOptions.includes('All')) {
        this.targetAudience.income = ['All'];
      } else {
        this.targetAudience.income = selectedOptions.filter(
          (option) => option !== 'All'
        );
      }
    },

    // Handle Selection Logic for Rule Set 2 Fields
    handleMaritalStatusSelection(selectedOptions) {
      if (selectedOptions.includes('All')) {
        this.targetAudience.maritalStatus = ['All'];
      } else if (selectedOptions.includes('N/A')) {
        this.targetAudience.maritalStatus = ['N/A'];
      } else {
        this.targetAudience.maritalStatus = selectedOptions.filter(
          (option) => option !== 'All' && option !== 'N/A'
        );
      }
    },
    handleFamilyLifecycleSelection(selectedOptions) {
      if (selectedOptions.includes('All')) {
        this.targetAudience.familyLifecycle = ['All'];
      } else if (selectedOptions.includes('N/A')) {
        this.targetAudience.familyLifecycle = ['N/A'];
      } else {
        this.targetAudience.familyLifecycle = selectedOptions.filter(
          (option) => option !== 'All' && option !== 'N/A'
        );
      }
    },
    handleEducationSelection(selectedOptions) {
      if (selectedOptions.includes('All')) {
        this.targetAudience.education = ['All'];
      } else if (selectedOptions.includes('N/A')) {
        this.targetAudience.education = ['N/A'];
      } else {
        this.targetAudience.education = selectedOptions.filter(
          (option) => option !== 'All' && option !== 'N/A'
        );
      }
    },
    handleRegionSelection(selectedOptions) {
      if (selectedOptions.includes('All')) {
        this.targetAudience.region = ['All'];
      } else if (selectedOptions.includes('N/A')) {
        this.targetAudience.region = ['N/A'];
      } else {
        this.targetAudience.region = selectedOptions.filter(
          (option) => option !== 'All' && option !== 'N/A'
        );
      }
    },
    handleCitySizeSelection(selectedOptions) {
      if (selectedOptions.includes('All')) {
        this.targetAudience.citySize = ['All'];
      } else if (selectedOptions.includes('N/A')) {
        this.targetAudience.citySize = ['N/A'];
      } else {
        this.targetAudience.citySize = selectedOptions.filter(
          (option) => option !== 'All' && option !== 'N/A'
        );
      }
    },
 // Section 2: Campaign Type
 toggleCampaignType(type) {
    const index = this.selectedCampaignTypes.indexOf(type);
    if (index > -1) {
      this.selectedCampaignTypes.splice(index, 1);
    } else {
      this.selectedCampaignTypes.push(type);
    }
  },
  isCampaignTypeSelected(type) {
    return this.selectedCampaignTypes.includes(type);
  },

  // Section 5: Key Value
  toggleKeyValue(keyvalue) {
    const index = this.selectedKeyValues.indexOf(keyvalue);
    if (index > -1) {
      this.selectedKeyValues.splice(index, 1);
    } else {
      this.selectedKeyValues.push(keyvalue);
    }
  },
  isKeyValueSelected(keyvalue) {
    return this.selectedKeyValues.includes(keyvalue);
  },

  // Section 6: Marketing Channels
  toggleMarketingChannel(channel) {
    const index = this.selectedMarketingChannels.indexOf(channel);
    if (index > -1) {
      this.selectedMarketingChannels.splice(index, 1);
    } else {
      this.selectedMarketingChannels.push(channel);
    }
  },
  isMarketingChannelSelected(channel) {
    return this.selectedMarketingChannels.includes(channel);
  },



    // Section 8: Creative Elements
    toggleElement(element) {
      const index = this.selectedElements.indexOf(element);
      if (index > -1) {
        this.selectedElements.splice(index, 1);
      } else {
        this.selectedElements.push(element);
      }
    },
    isElementSelected(element) {
      return this.selectedElements.includes(element);
    },

    toggleBrandStrategy(strategy) {
    const index = this.selectedBrandStrategies.indexOf(strategy);
    if (index > -1) {
      this.selectedBrandStrategies.splice(index, 1);
    } else {
      this.selectedBrandStrategies.push(strategy);
    }
  },
  isBrandStrategySelected(strategy) {
    return this.selectedBrandStrategies.includes(strategy);
  },

  // Section 9: Campaign Difference
  togglePreviousCampaignDiff(difference) {
    const index = this.selectedPreviousCampaignDiffs.indexOf(difference);
    if (index > -1) {
      this.selectedPreviousCampaignDiffs.splice(index, 1);
    } else {
      this.selectedPreviousCampaignDiffs.push(difference);
    }
  },
  isPreviousCampaignDiffSelected(difference) {
    return this.selectedPreviousCampaignDiffs.includes(difference);
  },

  // Section 10: Market Trends
  toggleMarketTrend(trend) {
    const index = this.selectedMarketTrends.indexOf(trend);
    if (index > -1) {
      this.selectedMarketTrends.splice(index, 1);
    } else {
      this.selectedMarketTrends.push(trend);
    }
  },
  isMarketTrendSelected(trend) {
    return this.selectedMarketTrends.includes(trend);
  },

  // Section 11: Tone Style
  toggleToneStyle(tone) {
    const index = this.selectedToneStyles.indexOf(tone);
    if (index > -1) {
      this.selectedToneStyles.splice(index, 1);
    } else {
      this.selectedToneStyles.push(tone);
    }
  },
  isToneStyleSelected(tone) {
    return this.selectedToneStyles.includes(tone);
  },

  // Section 12: Tone Reason
  toggleToneReason(reason) {
    const index = this.selectedToneReasons.indexOf(reason);
    if (index > -1) {
      this.selectedToneReasons.splice(index, 1);
    } else {
      this.selectedToneReasons.push(reason);
    }
  },
  isToneReasonSelected(reason) {
    return this.selectedToneReasons.includes(reason);
  },
    // Submit the Questionnaire
    async submitQuestionnaire() {
      let hasError = false;

      // Validate Section 0: Sell Type
      if (!this.sellType) {
        this.hasErrors.sellType = true;
        hasError = true;
        console.log('Error in Sell Type: No sell type provided');
      } else {
        this.hasErrors.sellType = false;
      }

      // Validate Section 2: Objectives
      if (this.selectedObjectives.length === 0) {
        this.hasErrors.objectives = true;
        hasError = true;
        console.log('Error in Objectives: No objectives selected');
      } else {
        this.hasErrors.objectives = false;
      }

      // Validate Section 3: Target Audience
      if (this.targetAudience.age.length === 0) {
          this.hasErrors.targetAudience = true;
          hasError = true;
          console.log('Error in Target Audience: At least one age group must be selected');
        } else {
          this.hasErrors.targetAudience = false;
        }
        if (this.targetAudience.region.length === 0) {
            this.hasErrors.targetAudience = true;
            hasError = true;
            console.log('Error in Region: At least one region must be selected');
          } else {
            this.hasErrors.targetAudience = false;
          }
        
        if (this.targetAudience.familyLifecycle.length === 0) {
          this.hasErrors.targetAudience = true;
          hasError = true;
          console.log('Error in Family Lifecycle: At least one option must be selected');
        } else {
          this.hasErrors.targetAudience = false;
        }

          if (this.targetAudience.citySize.length === 0) {
            this.hasErrors.targetAudience = true;
            hasError = true;
            console.log('Error in City Size: At least one city size must be selected');
          } else {
            this.hasErrors.targetAudience = false;
          }

          if (this.targetAudience.urbancity.length === 0) {
            this.hasErrors.targetAudience = true;
            hasError = true;
            console.log('Error in Urbanicity: At least one option must be selected');
          } else {
            this.hasErrors.targetAudience = false;
          }

          if (this.targetAudience.maritalStatus.length === 0) {
            this.hasErrors.targetAudience = true;
            hasError = true;
            console.log('Error in Marital Status: At least one option must be selected');
          } else {
            this.hasErrors.targetAudience = false;
          }

          if (this.targetAudience.education.length === 0) {
            this.hasErrors.targetAudience = true;
            hasError = true;
            console.log('Error in Education: At least one option must be selected');
          } else {
            this.hasErrors.targetAudience = false;
          }

          if (this.targetAudience.income.length === 0) {
            this.hasErrors.targetAudience = true;
            hasError = true;
            console.log('Error in Income: At least one option must be selected');
          } else {
            this.hasErrors.targetAudience = false;
          }
        if (this.selectedCampaignTypes.length === 0) {
          this.hasErrors.campaignType = true;
          hasError = true;
        } else {
          this.hasErrors.campaignType = false;
        }

        if (this.selectedKeyValues.length === 0 && !this.customKeyValue) {
          this.hasErrors.keyValue = true;
          hasError = true;
        } else {
          this.hasErrors.keyValue = false;
        }

        if (this.selectedMarketingChannels.length === 0 && !this.customMarketingChannel) {
          this.hasErrors.marketingChannels = true;
          hasError = true;
        } else {
          this.hasErrors.marketingChannels = false;
        }
    
      // Validate Section 8: Creative Elements
      if (
        this.selectedElements.length === 0 &&
        !this.customElement
      ) {
        this.hasErrors.creativeElements = true;
        hasError = true;
        console.log('Error in Creative Elements: No elements selected');
      } else {
        this.hasErrors.creativeElements = false;
      }

    // Section 7: Brand Strategy
    if (this.selectedBrandStrategies.length === 0 && !this.customBrandStrategy) {
      this.hasErrors.brandStrategy = true;
      hasError = true;
      console.log('Error in Brand Strategy: No strategy selected');
    } else {
      this.hasErrors.brandStrategy = false;
    }

    // Section 9: Campaign Difference
    if (this.selectedPreviousCampaignDiffs.length === 0 && !this.customPreviousCampaignDiff) {
      this.hasErrors.previousCampaignDiff = true;
      hasError = true;
      console.log('Error in Campaign Difference: No difference selected');
    } else {
      this.hasErrors.previousCampaignDiff = false;
    }

    // Section 10: Market Trends
    if (this.selectedMarketTrends.length === 0 && !this.customMarketTrend) {
      this.hasErrors.marketTrend = true;
      hasError = true;
      console.log('Error in Market Trends: No trend selected');
    } else {
      this.hasErrors.marketTrend = false;
    }

    // Section 11: Tone Style
    if (this.selectedToneStyles.length === 0 && !this.customToneStyle) {
      this.hasErrors.toneStyle = true;
      hasError = true;
      console.log('Error in Tone Style: No tone style selected');
    } else {
      this.hasErrors.toneStyle = false;
    }

    // Section 12: Tone Reason
    if (this.selectedToneReasons.length === 0 && !this.customToneReason) {
      this.hasErrors.toneReason = true;
      hasError = true;
      console.log('Error in Tone Reason: No tone reason selected');
    } else {
      this.hasErrors.toneReason = false;
    }



      // Check if there are any errors
      if (hasError) {
        alert('Please complete all required fields.');
        return;
      }

      // Prepare the data to send to Google Forms
      const formData = new URLSearchParams();
      // Utility function for appending options (including "Other")
      function appendOptions(field, selectedOptions, customOption, entryID) {
        let hasOther = false;

        if (selectedOptions.includes('Other')) {
          hasOther = true;
          // Remove 'Other' from selectedOptions
          selectedOptions = selectedOptions.filter(option => option !== 'Other');
        }

        // Append regular options
        if (selectedOptions.length > 0) {
          selectedOptions.forEach(option => {
            formData.append(entryID, option);
          });
        }

        // Handle 'Other' option
        if (hasOther && customOption) {
          // Append '__other_option__' to the main entry ID
          formData.append(entryID, '__other_option__');
          // Append the custom 'Other' text with '.other_option_response'
          formData.append(`${entryID}.other_option_response`, customOption);
        }

        // If no options selected at all, append 'N/A'
        if (!selectedOptions.length && !hasOther) {
          formData.append(entryID, 'N/A');
        }
      }

      // Example: Sell Type (Simple Field)
      formData.append('entry.617589951', this.sellType || 'N/A');

      // Example: Campaign Types
      appendOptions('campaignTypes', this.selectedCampaignTypes, null, 'entry.934550263');

      // Example: Objectives
      appendOptions('objectives', this.selectedObjectives, null, 'entry.599150444');

        // For Age
        this.targetAudience.age.forEach(age => {
        formData.append('entry.376877753', age); // Replace with correct Google Form entry ID
      });

      // For Marital Status
      this.targetAudience.maritalStatus.forEach(status => {
        formData.append('entry.22969741', status); // Replace with correct Google Form entry ID
      });

      // For Family Lifecycle
      this.targetAudience.familyLifecycle.forEach(lifecycle => {
        formData.append('entry.1001452286', lifecycle); // Replace with correct Google Form entry ID
      });

      // For Income
      this.targetAudience.income.forEach(income => {
        formData.append('entry.1669175268', income); // Replace with correct Google Form entry ID
      });

      // For Education
      this.targetAudience.education.forEach(education => {
        formData.append('entry.787220392', education); // Replace with correct Google Form entry ID
      });
      formData.append('entry.103915780', this.targetAudience.gender); // Gender

      // For Region
      this.targetAudience.region.forEach(region => {
        formData.append('entry.195503636', region);
      });

      // For City Size
      this.targetAudience.citySize.forEach(citySize => {
        formData.append('entry.586542321', citySize);
      });

      // For Urbanicity
      this.targetAudience.urbancity.forEach(urbancity => {
        formData.append('entry.441556711', urbancity);
      });

      // Example: Key Values
      appendOptions('keyValues', this.selectedKeyValues, this.customKeyValue, 'entry.1662687644');

      // Example: Marketing Channels
      appendOptions('marketingChannels', this.selectedMarketingChannels, this.customMarketingChannel, 'entry.113966502');

      // Example: Brand Strategies
      appendOptions('brandStrategies', this.selectedBrandStrategies, this.customBrandStrategy, 'entry.557701969');

      // Example: Creative Elements
      appendOptions('creativeElements', this.selectedElements, this.customElement, 'entry.985729999');

      // Example: Previous Campaign Differences
      appendOptions('previousCampaignDiff', this.selectedPreviousCampaignDiffs, this.customPreviousCampaignDiff, 'entry.377959753');

      // Example: Market Trends
      appendOptions('marketTrends', this.selectedMarketTrends, this.customMarketTrend, 'entry.871770820');

      // Example: Tone Styles
      appendOptions('toneStyles', this.selectedToneStyles, this.customToneStyle, 'entry.504881477');

      // Example: Tone Reasons
      appendOptions('toneReasons', this.selectedToneReasons, this.customToneReason, 'entry.42474847');

      // Example: Email
      formData.append('entry.543760413', this.email || 'N/A');

      // Send the data to the Google Form
      try {
            // Create the full URL with all the form data as query parameters
        const url = `https://docs.google.com/forms/d/e/1FAIpQLSefDKVvigAsFcW1inqj8y_qUwgA5jdzjeXBWmSRhWu2erhMOw/formResponse?${formData.toString()}`;
        console.log(url);
        // Open the URL in a new tab to submit the form
        window.open(url, '_blank');

        this.submitted = true;
      } catch (error) {
        console.error('Error submitting the form:', error);
        alert('There was an issue submitting the form.');
      }
    }
  }
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.multiselect-tag {
  background: #f4633a !important;
  font-size: 10px !important;
  max-width: 100px !important;
  min-width: 55px !important;
}
.multiselect.is-active {
  box-shadow: none !important;
}
.multiselect-option {
  font-size: 11px !important;
}
.multiselect-wrapper {
  font-size: 12px !important;
  background: #381e72 !important;
  color: white !important;
}
.multiselect-wrapper::placeholder {
  color: white !important;
}
</style>
<style scoped>
/* General Page Layout */
.demo-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
}
.sticky-header {
  max-width: 1200px;
}

/* Title Styling */
.title {
  font-size: 2em;
  margin-bottom: 40px;
  color: #381e72;
  text-align: center;
  margin-top: 15%;
}
/* Thank You Message Styling */
.thank-you-message {
  text-align: center;
  margin-top: 100px;
}

.thank-you-icon {
  font-size: 3em;
  color: #28a745;
  margin-bottom: 20px;
}

.thank-you-message h2 {
  font-size: 1.8em;
  color: #381e72;
  margin-bottom: 20px;
}

/* Home Button Styling */
.home-button {
  background-color: #381e72;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}

.home-button:hover {
  background-color: #f4633a;
}
/* Question Section Styling */
.question-section {
  margin-bottom: 50px;
  margin-top: 5%;
}

.question-section h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #381e72;
}

.question-section h2.error {
  color: red;
}

/* Dropdown Group Styling */
.dropdown-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.dropdown-group label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.dropdown-group select {
  width: 100%;
  height: 42px;
  padding: 8px;
  border: 1px solid #381e72;
  background-color: #381e72;
  color: white;
  border-radius: 5px;
}
.dropdown-group select.placeholder {
  color: white;
}

/* Button Container */
.question-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.question-buttons button {
  padding: 10px 20px;
  background-color: #381e72;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex: 1 1 calc(33% - 30px);
  max-width: calc(33% - 30px);
  margin-bottom: 10px;
  text-align: center;
}

.question-buttons button.selected {
  background-color: #f4633a;
}

.question-buttons button:hover:not(.selected) {
  background-color: #573b89;
}

/* Other Input Styling */
.other-input {
  max-width: 92%;
  width: 100%;
  margin-top: 15px;
}

.other-input input {
  width: 100%;
  padding: 10px;
  border: 1px solid #381e72;
  border-radius: 5px;
}

/* Submit Button */
.submit-container {
  text-align: center;
  margin-top: 50px;
}

.submit-button {
  padding: 15px 30px;
  background-color: #381e72;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #f4633a;
}

/* Error State Styling */
.error {
  color: red;
}

.error span {
  color: red;
  margin-left: 5px;
}

/* Optional custom styles */
.multiselect {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #381e72;
  background-color: #f8f8f8;
}

.multiselect__option--selected {
  background-color: #381e72;
  color: white;
}
</style>
